import { useCallback, useContext, useEffect, useLayoutEffect, useState } from 'react';

import { useApolloClient } from '@apollo/client';
import { IInstitutionSettings } from '@ascd/witsby-components';
import { Player } from '@lottiefiles/react-lottie-player';
import { Box } from '@mui/material';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import random from 'lodash/random';
import { AppContext } from '@contexts/appContext';
import GET_IMAGE_URL from '@graphql/schema/getImageUrl.graphql';
import { getBannerAnimation } from '@utils';

interface IAnimation {
  isAdminPage?: boolean;
  defaultAnimation?: IInstitutionSettings['homeBannerImage'];
}

const Animation = ({ isAdminPage = false, defaultAnimation }: IAnimation) => {
  const apolloClient = useApolloClient();
  const {
    state: { ascdInstitutionSettings },
  } = useContext(AppContext);

  const homeBannerImage = get(ascdInstitutionSettings, 'settings.homeBannerImage');
  const showAnimation = isAdminPage || getBannerAnimation(homeBannerImage);

  let animationType = get(homeBannerImage, 'animationType', 'ICON');
  let animationIcon: string = get(homeBannerImage, 'animationIcon', '');
  let animationJson: string = get(homeBannerImage, 'animationJson', '');

  if (isAdminPage && !isEmpty(defaultAnimation)) {
    animationType = get(defaultAnimation, 'animationType', animationType);
    animationIcon = get(defaultAnimation, 'animationIcon', animationIcon);
    animationJson = get(defaultAnimation, 'animationJson', animationJson);
  }

  const [count, setCount] = useState(animationIcon ? 0 : 50);
  const [animationIconUrl, setAnimationIconUrl] = useState('');

  const fetchImage = useCallback(
    async (imageName: string) => {
      const response = await apolloClient.query({
        query: GET_IMAGE_URL,
        variables: { imageName },
        fetchPolicy: 'network-only',
      });

      return response?.data?.getImageUrl?.url || '';
    },
    [apolloClient],
  );

  useEffect(() => {
    if (animationIcon) {
      const isValidUrl = animationIcon.startsWith('http') || animationIcon.startsWith('https');
      if (isValidUrl) {
        setAnimationIconUrl(animationIcon);
      } else {
        fetchImage(animationIcon).then((url) => setAnimationIconUrl(url));
      }
    } else {
      setAnimationIconUrl('');
    }
  }, [animationIcon, fetchImage]);

  useLayoutEffect(() => {
    if (!count || !showAnimation || animationType === 'JSON') return;

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < count; i++) {
      const animation = document.createElement('div');
      animation.classList.add('animation');
      if (animationIconUrl) {
        animation.innerHTML = `<img alt="animation-icon" class="animation-icon" src="${animationIconUrl}" />`;
      } else {
        animation.innerHTML = '❅';
      }
      animation.style.setProperty('--left', `${random(0, 92)}vw`);
      animation.style.setProperty('--left-ini', `${random(20) - 10}vw`);
      animation.style.setProperty('--left-end', `${random(20) - 10}vw`);
      animation.style.setProperty('--speed', `${5 + random(15)}s`);
      animation.style.setProperty('--size', `${random(5) * 0.3}vw`);
      animation.style.setProperty('--delay', `-${random(15)}s`);
      document.body.appendChild(animation);
      setTimeout(() => animation.remove(), 50 * 1000);
    }
  }, [count, animationType, showAnimation, animationIconUrl]);

  if (!showAnimation) return null;
  if (animationIcon && !animationIconUrl) return null;

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {animationIconUrl && (
        <Box sx={{ display: 'none' }}>
          <img alt="animation-icon" onLoad={() => setCount(50)} src={animationIconUrl} />
        </Box>
      )}
      {animationType === 'JSON' && (
        <Player loop={5} autoplay speed={1} src={animationJson} className="lottie-animation" />
      )}
    </>
  );
};

export default Animation;
